<template>
    <div class="main-container">
        <section class="section-contact">
            <h1 class="title-solo">Contact us</h1>
            <ContactForm></ContactForm>
        </section>
    </div>
</template>

<script>
import ContactForm from './../components/ContactForm.vue'

export default {
    name : 'Contact',
    components : {
        ContactForm
    }
}
</script>

<style lang="scss" scoped>
    .main-container {
        background:#f4f4f4;

        background-repeat: no-repeat;
        padding:80px 0;
        margin-top:-80px;

    }

    .section-contact {
        background-image: url('./../assets/bg-contact.jpg');
        background-position: center;
        background-size: cover;
    }
</style>